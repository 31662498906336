import carImg from "../images/content/carBg.webp";
import L9Img from "../images/png/car/L9.png";
// slider
// l9
import l9Img1 from "../images/png/car/l9/1.jpg";
import l9Img2 from "../images/png/car/l9/2.jpg";
import l9Img3 from "../images/png/car/l9/3.jpg";
import l9Img4 from "../images/png/car/l9/4.jpg";
import l9Img5 from "../images/png/car/l9/5.jpg";
import l9Img6 from "../images/png/car/l9/6.jpg";
// l7
import l7Img1 from "../images/png/car/l7/1.jpg";
import l7Img2 from "../images/png/car/l7/2.jpg";
import l7Img3 from "../images/png/car/l7/3.jpg";
import l7Img4 from "../images/png/car/l7/4.jpg";
import l7Img5 from "../images/png/car/l7/5.jpg";
import l7Img6 from "../images/png/car/l7/6.jpg";
import l7Img7 from "../images/png/car/l7/7.png";
// l6
import l6Img1 from "../images/png/car/l6/2.png";
import l6Img2 from "../images/png/car/l6/3.png";
import l6Img3 from "../images/png/car/l6/4.png";
import l6Img4 from "../images/png/car/l6/5.png";
import l6Img5 from "../images/png/car/l6/6.png";
import l6Img6 from "../images/png/car/l6/7.png";

// Gallery
import l9content1 from "../images/content/car/l9/1.jpg";
import l9content2 from "../images/content/car/l9/2.jpg";
import l9content3 from "../images/content/car/l9/3.jpg";
import l9content4 from "../images/content/car/l9/4.jpg";
import l9content5 from "../images/content/car/l9/5.jpg";
import l9content6 from "../images/content/car/l9/6.jpg";
import l9content7 from "../images/content/car/l9/7.jpg";
import l9content8 from "../images/content/car/l9/8.jpg";
import l9content9 from "../images/content/car/l9/9.jpg";
import l9content10 from "../images/content/car/l9/10.jpg";
import l9content11 from "../images/content/car/l9/11.jpg";
import l9content12 from "../images/content/car/l9/12.jpg";
import l9content13 from "../images/content/car/l9/13.jpg";
import l9content14 from "../images/content/car/l9/14.jpg";
import l9content15 from "../images/content/car/l9/15.jpg";
import l9content16 from "../images/content/car/l9/16.jpg";
import l9content17 from "../images/content/car/l9/17.jpg";
import l9content18 from "../images/content/car/l9/18.jpg";
import l9content19 from "../images/content/car/l9/19.jpg";
//l7 gallery

import l7content1 from "../images/content/car/l7/1.jpg";
import l7content2 from "../images/content/car/l7/2.jpg";
import l7content3 from "../images/content/car/l7/3.jpg";
import l7content4 from "../images/content/car/l7/4.jpg";
import l7content5 from "../images/content/car/l7/5.jpg";
import l7content6 from "../images/content/car/l7/6.jpg";
import l7content7 from "../images/content/car/l7/7.jpg";
import l7content8 from "../images/content/car/l7/8.jpg";
import l7content9 from "../images/content/car/l7/9.jpg";
import l7content10 from "../images/content/car/l7/10.jpg";
import l7content11 from "../images/content/car/l7/11.jpg";
import l7content12 from "../images/content/car/l7/12.jpg";
import l7content13 from "../images/content/car/l7/13.jpg";
import l7content14 from "../images/content/car/l7/14.jpg";
import l7content15 from "../images/content/car/l7/15.jpg";
import l7content16 from "../images/content/car/l7/16.jpg";
import l7content17 from "../images/content/car/l7/17.jpg";
import l7content18 from "../images/content/car/l7/18.jpg";
import l7content19 from "../images/content/car/l7/19.jpg";

// Номер телефона:89362858898
// WhatsApp：89362858898
// Цены:
// Lixiang L6:6600000р.
// Lixiang L7:7300000р.
// Lixiang L9:8650000р.

export const lixiangData = [
  {
    banner: carImg,
    title: "LiXiang L9",
    slider: [l9Img5, l9Img1, l9Img2, l9Img3, l9Img4, l9Img6],
    gallery: [
      l9content1,
      l9content2,
      l9content3,
      l9content4,
      l9content5,
      l9content6,
      l9content7,
      l9content8,
      l9content9,
      l9content10,
      l9content11,
      l9content12,
      l9content13,
      l9content14,
      l9content15,
      l9content16,
      l9content17,
      l9content18,
      l9content19,
    ],
    version: [
      // {
      //   model: "Pro",
      //   price: "8650000",
      // },
      {
        model: "Ultra",
        price: "8900000",
      },
    ],
    descripition: {
      title: "LiXiang L9",
      content: [
        {
          subtitle: "Li Auto L9 рестайлинг 2024 года",
          text: `Li L9 - это флагманский 6-местный семейный кроссовер премиум-класса. Li L9 является конкурентом Mercedes-Benz GLS и BMW X7, и превосходит эти модели не только по габаритам, но и по оснащению`,
        },
        {
          subtitle: "Технические характеристики Li L9:",
          text: `Li L9 имеет последовательную гибридную силовую установку (EREV) с 1,5-литровым турбомотором и двумя электромоторами. При такой силовой схеме машины оснащены трансмиссией, как у электромобиля. А бензиновый двигатель внутреннего сгорания работает только на питание электродвигателей и не имеет привода на колеса.

Общая мощность составляет 449 л.с., а максимальный крутящий момент — 620 Нм. Привод только полный. Ёмкость тяговой тройной литиевой батареи от CATL составляет 52,3 кВт·ч для всех комплектаций. Запас хода на чистом электричестве — 240 км, запас хода полностью заправленной и заряженной машины — 1412 км. До 100 км/ч кроссовер разгоняется за 5,3 с, максимальная скорость - 180 км / ч.

Для пополнения заряда аккумулятора с 20% до 80% потребуется 40 минут. При медленной зарядке для пополнения заряда с 0% до 100% потребуется 8 часов.

Рестайлинговый Li L9 оснастили двойной пневмоподвеской с технологией CDC, спереди двойной рычаг, сзади многорычажка. Имеется 5 регулировок по высоте от 136 мм до 216 мм. Все это обеспечивает более плавное и комфортное вождение.

Li L9 стандартно поставляется с бесплатными системами помощи при вождении, но имеет разные возможности в зависимости от конфигурации. В базе также имеется 9 подушек безопасности.`,
        },
        {
          subtitle: "Экстерьер Li L9:",
          text: `Внедорожник Li L9 в рестайлинге 2024 года не получил каких-либо изменений в области внешнего вида кузова. Он по-прежнему является самым большим и максимально оснащенным в линейке кроссоверов Li Auto. Li Auto L9 имеет размеры 5218*1998*1800 мм, а колесная база составляет 3100 мм. Шины R21 стандартно для всех комплектаций.`,
        },
        {
          subtitle: "Интерьер Li L9:",
          text: `Li L9 имеет три ряда сидений и шесть посадочных мест. Сиденья выполнены из натуральной кожи наппа.

Все комплектации Ideal L9 теперь идут с 16-точечным массажем первого и второго рядов сидений, обогревом и вентиляцией. В третьем ряду только обогрев.

Внутри кабина пилота оснащена пятью экранами, состоящими из центрального экрана управления, развлекательного экрана для второго пилота, развлекательного экрана для пассажиров 2го ряда, экрана на рулевом колесе и HUD-проекцией на лобовое стекло. Три экрана имеют диагональ 15,7", разрешение 3K и обновлены до OLED экрана Samsung. Встроенный чип Qualcomm Snapdragon 8295P.

Кроме этого, первоклассный комфорт в салоне обеспечивается аудиосистемой Dolby Atmos с 21 динамиками из которых 3 матричные сабвуферы, 3-х зонным климат-контролем, очистителем воздуха, атмосферной подсветкой из 256 цветов, панелями беспроводной зарядки, холодильником с функцией подогрева, столиком и возможностью сложить сиденья в идеально ровную двуспальную кровать.

Водительское кресло было модернизировано до типа “ковер-самолет”, во втором ряду справа появилось кресло "с нулевой гравитацией".

Насладиться поездкой также помогут двойное остекление спереди и сзади и панорамная крыша`,
        },
        {
          subtitle: "Отличие комплектаций Li L9:",
          text: `Lixiang L9 доступен в двух комплектациях: Pro и Ultra.

Комплектации отличаются наличием лидара и выдвижной электрической подножки.`,
        },
        // {
        //   subtitle: "",
        //   text: ``,
        // },
      ],
    },
    table: [
      ["L9", "Pro", "Ultra", "Manual sort"],
      ["Тип кузова:", "Кроссовер (6 мест)", "Кроссовер (6 мест)", "0k"],
      ["Официальное время разгона 0—100 км/ч", "5,3 сек.", "5,3 сек.", "0m"],
      ["Максимальная скорость", "180 (км/ч)", "180 (км/ч)", "0n"],
      [
        "Запас хода на чистом электричестве WLTC / CLTC",
        "235 / 280 (км)",
        "235 / 280 (км)",
        "0o",
      ],
      [
        "Полный запас хода WLTC / CLTC",
        "1176 / 1412 (км)",
        "1176 / 1412 (км)",
        "0p",
      ],
      [
        "Длина х Ширина х Высота",
        "5218x1998x1800 (мм)",
        "5218x1998x1800 (мм)",
        "0q",
      ],
      [
        "Масса: Снаряженная / Полной нагрузки",
        "2570 / 3170 (кг)",
        "2570 / 3170 (кг)",
        "0r",
      ],
      [
        "Описание двигателя",
        "Расширенный диапазон 449 лошадиных сил",
        "Расширенный диапазон 449 лошадиных сил",
        "0s",
      ],
      [
        "Общая мощность",
        "449 лошадиных сил / 330 (квт)",
        "449 лошадиных сил / 330 (квт)",
        "0t",
      ],
      [
        "Крутящий момент перед.+ зад.",
        "220 + 400 (Н·м)",
        "220 + 400 (Н·м)",
        "0u",
      ],
      ["Емкость аккумулятора", "52,3 (кВтч)", "52,3 (кВтч)", "0v"],
      [
        "Время зарядки",
        "Быстрая зарядка 0,5 часа, медленная зарядка 7,9 часа.",
        "Быстрая зарядка 0,5 часа, медленная зарядка 7,9 часа.",
        "1",
      ],
      ["Объем", "1496 (мл)", "1496 (мл)", "14"],
      ["Рабочий объем", "1,5 (л)", "1,5 (л)", "18"],
      ["Форма приема", "с турбонаддувом", "с турбонаддувом", "1c"],
      ["Пневматическая подвеска", "●", "●", "1g"],
      [
        "Переменная регулировка подвески",
        "Мягкость / жёсткость Выше / ниже",
        "Мягкость / жёсткость Выше / ниже",
        "1k",
      ],
      ["Электропороги", "Опционально", "●", "1o"],
      ["Задний ЖК-экран", "●", "●", "1m"],
      ["Аудио / Количество динамиков (шт.)", "21", "21", "2"],
      [
        "Контроль температурной зоны",
        "Трёхзонный кондиционер",
        "Трёхзонный кондиционер",
        "2g",
      ],
      [
        "Операционная система помощи при вождении",
        "Ideal AD Pro",
        "Ideal AD Pro",
        "3",
      ],
      [
        "Чип для помощи при вождении",
        "Horizon Journey 5 Chip",
        "NVIDIA DRIVE Orin X*2",
        "3g",
      ],
      [
        "Вспомогательная вычислительная мощность чипа (TOPS)",
        "128",
        "508",
        "4",
      ],
      [
        "Автомобильный смарт-чип",
        "Qualcomm Snapdragon 8295P High Performance Edition",
        "Qualcomm Snapdragon 8295P High Performance Edition",
        "6",
      ],
      ["Оперативная память автомобиля (ГБ)", "32", "32", "8"],
      ["Количество камер снаружи автомобиля (шт.)", "10", "11", "g"],
    ],
  },
  {
    banner: carImg,
    title: "LiXiang L7",
    slider: [l7Img5, l7Img1, l7Img2, l7Img3, l7Img4, l7Img6, l7Img7],
    gallery: [
      l7content1,
      l7content2,
      l7content3,
      l7content4,
      l7content5,
      l7content6,
      l7content7,
      l7content8,
      l7content9,
      l7content10,
      l7content11,
      l7content12,
      l7content13,
      l7content14,
      l7content15,
      l7content16,
      l7content17,
      l7content18,
      l7content19,
    ],
    version: [
      // {
      //   model: "Pro",
      //   price: "5936000",
      // },
      {
        model: "Ultra",
        price: "7450000",
      },
    ],
    descripition: {
      title: "LiXiang L7",
      content: [
        {
          subtitle: "Li Auto L7 рестайлинг 2024 года",
          text: `Li L7 - это первая пятиместная модель Li Auto, которая после рестайлинга 2024 года стала сопоставима с флагманским Li Auto L9 по уровню оснащения.`,
        },
        {
          subtitle: "Технические характеристики Li L7:",
          text: `Li L7 имеет последовательную гибридную силовую установку (EREV) с 1,5-литровым турбомотором и двумя электромоторами. При такой силовой схеме машины оснащены трансмиссией, как у электромобиля. А бензиновый двигатель внутреннего сгорания работает только на питание электродвигателей и не имеет привода на колеса. 

Общая мощность составляет 449 л.с., а максимальный крутящий момент — 620 Нм. Привод только полный. Ёмкость тяговой тройной литиевой батареи от CATL составляет 52,3 кВт·ч в максимальной комплектации. Запас хода на чистом электричестве — 240 км, запас хода полностью заправленной и заряженной машины — 1421 км. До 100 км/ч кроссовер разгоняется за 5,3 с, максимальная скорость - 180 км / ч.

Для пополнения заряда аккумулятора с 20% до 80% потребуется 40 минут. При медленной зарядке для пополнения заряда с 0% до 100% потребуется 8 часов.

 

Рестайлинговый Li L7 оснастили пневмоподвеской с технологией CDC, спереди двойной рычаг, сзади многорычажка. Имеется 5 регулировок по высоте от 136 мм до 216 мм. Все это обеспечивает более плавное и комфортное вождение.

 

Li L7 стандартно поставляется с бесплатными системами помощи при вождении, но имеет разные возможности в зависимости от конфигурации. В базе также имеется 6 подушек безопасности.`,
        },
        {
          subtitle: "Экстерьер Li L7:",
          text: `Внешне Li L7 мало чем отличается от своих собратьев шестой, восьмой и девятой серий. В рамках рестайлинга 2024 года было изменено только расположение номерного знака: он перенесен на крышку багажника.

Li Auto L7 имеет размеры 5050*1995*1750 мм, а колесная база составляет 3005 мм. Шины R20, R21 только в Li L7 Ultra.`,
        },
        {
          subtitle: "Интерьер Li L7:",
          text: `Li L7 имеет два ряда сидений и пять посадочных мест. Сиденья выполнены из натуральной кожи наппа. Все комплектации Ideal L7 теперь идут с десятиточечным массажем первого и второго рядов сидений, обогревом и вентиляцией.

Внутри кабина пилота оснащена четырьмя экранами, состоящими из центрального экрана управления, развлекательного экрана для второго пилота, экрана на рулевом колесе и HUD-проекцией на лобовое стекло. Два экрана имеют диагональ 15,7", разрешение 3K и встроенный чип Qualcomm Snapdragon 8295P.

Развлекательный пятый экран для пассажиров 2го ряда имеется только в комплектации Ultra.

 

Кроме этого, первоклассный комфорт в салоне обеспечивается аудиосистемой Dolby Atmos с 21 динамиками из которых 3 матричные сабвуферы, 3-х зонным климат-контролем, очистителем воздуха, атмосферной подсветкой из 256 цветов, панелями беспроводной зарядки, холодильником с функцией подогрева, оттоманкой и возможностью сложить сиденья в идеально ровную двуспальную кровать.

Насладиться поездкой также помогут двойное остекление спереди и сзади и панорамная крыша.`,
        },
        {
          subtitle: "Отличие комплектаций Li L7:",
          text: `Lixiang L7 доступен в трех комплектациях: Pro, Max и Ultra.

Комплектации отличаются ёмкостью батареи, аудиосистемой, наличием лидара, 5го экрана в задней части салона и наличием холодильника.`,
        },
        // {
        //   subtitle: "",
        //   text: ``,
        // },
      ],
    },
    table: [
      ["Lixiang L7", "Air", "Pro", "Max", "Ultra"],
      [
        "Тип кузова:",
        "Кроссовер (5 мест)",
        "Кроссовер (5 мест)",
        "Кроссовер (5 мест)",
        "Кроссовер (5 мест)",
      ],
      [
        "Официальное время разгона 0—100 км/ч",
        "5,3 сек.",
        "5,3 сек.",
        "5,3 сек.",
        "5,3 сек.",
      ],
      [
        "Запас хода на чистом электричестве",
        "210 (км)",
        "210 (км)",
        "210 (км)",
        "210 (км)",
      ],
      ["Полный запас хода", "1360 (км)", "1360 (км)", "1421 (км)", "1421 (км)"],
      ["Объем багажного отделения (л)", "495", "495", "495", "495"],
      [
        "Длина х Ширина х Высота",
        "5050x1995x1750 (мм)",
        "5050x1995x1750 (мм)",
        "5050x1995x1750 (мм)",
        "5050x1995x1750 (мм)",
      ],
      [
        "Описание двигателя",
        "Расширенный диапазон 449 лошадиных сил",
        "Расширенный диапазон 449 лошадиных сил",
        "Расширенный диапазон 449 лошадиных сил",
        "Расширенный диапазон 449 лошадиных сил",
      ],
      [
        "Режим вождения",
        "Двухмоторный полный привод",
        "Двухмоторный полный привод",
        "Двухмоторный полный привод",
        "Двухмоторный полный привод",
      ],
      [
        "Емкость аккумулятора",
        "42,8 (кВтч)",
        "42,8 (кВтч)",
        "52,3 (кВтч)",
        "52,3 (кВтч)",
      ],
      ["Общий крутящий момент двигателя (Н·м)", "620", "620", "620", "620"],
      ["Пневматическая подвеска", "●", "●", "●", "●"],
      ["Колесная база", "3005 (мм)", "3005 (мм)", "3005 (мм)", "3005 (мм)"],
      [
        "Максимальная скорость",
        "180 (км/ч)",
        "180 (км/ч)",
        "180 (км/ч)",
        "180 (км/ч)",
      ],
      [
        "Масса: Снаряженная / Полной нагрузки",
        "2460 / 3130 (кг)",
        "2460 / 3130 (кг)",
        "2500 / 3130 (кг)",
        "2500 / 3130 (кг)",
      ],
      ["Задний ЖК-экран", "—", "—", "●", "●"],
      ["Аудио / Количество динамиков (шт.)", "19", "19", "21", "21"],
      [
        "Операционная система помощи при вождении",
        "Ideal AD Pro",
        "Ideal AD Max",
        "Ideal AD Max",
        "Ideal AD Max",
      ],
      [
        "Чип для помощи при вождении",
        "horizon journey 5",
        "Двойной Nvidia Orin-X",
        "Двойной Nvidia Orin-X",
        "Двойной Nvidia Orin-X",
      ],
      [
        "Вспомогательная вычислительная мощность чипа (TOPS)",
        "128",
        "508",
        "508",
        "508",
      ],
      [
        "Автомобильный смарт-чип",
        "Qualcomm Snapdragon 8295P",
        "Qualcomm Snapdragon 8295P",
        "Qualcomm Snapdragon 8295P Высокопроизводительная версия",
        "Qualcomm Snapdragon 8295P Высокопроизводительная версия",
      ],
      ["Оперативная память автомобиля (ГБ)", "24", "24", "32", "32"],
      ["Системная память автомобиля (ГБ)", "256", "256", "256", "256"],
      ["Количество камер снаружи автомобиля (шт.)", "10", "11", "11", "11"],
      ["Количество лидаров (шт.)", "1", "1", "2", "2"],
    ],
  },
  {
    banner: carImg,
    title: "LiXiang L6",
    slider: [l6Img1, l6Img2, l6Img3, l6Img4, l6Img5, l6Img6],
    gallery: [
      l9content1,
      l9content2,
      l9content3,
      l9content4,
      l9content5,
      l9content6,
      l9content7,
      l9content8,
      l9content9,
      l9content10,
      l9content11,
      l9content12,
      l9content13,
      l9content14,
      l9content15,
      l9content16,
      l9content17,
      l9content18,
      l9content19,
    ],
    version: [
      {
        model: "Max",
        price: "6100000",
      },
    ],
    descripition: {
      title: "LiXiang L6",
      content: [
        {
          subtitle: "LiXiang L6 — Премиум внедорожник",
          text: `LiXiang L6 — это новый большой внедорожник премиум-класса от LiAuto, предлагающий просторный салон и передовые конфигурации. Этот автомобиль обладает высокими показателями производительности и безопасности, делая его отличным выбором для ценителей роскоши и комфорта.`,
        },
        {
          subtitle: "Технические характеристики LiXiang L6:",
          text: `LiXiang L6 оснащен двухмоторной системой полного привода и использованием расширения запаса хода на основе литий-железо-фосфатной батареи последнего поколения. Общая мощность системы составляет 300 кВт. 
      
      Максимальная скорость разгона от 0 до 100 км/ч составляет 5,4 секунды. Запас хода на чистом электричестве достигает 212 км, а полный запас хода составляет до 1390 км. Время зарядки аккумулятора с 20% до 80% составляет 40 минут, а полный заряд с 0% до 100% — 8 часов.
      
      LiXiang L6 оснащен передовой пневматической подвеской с технологией CDC и многорычажной задней подвеской, обеспечивая комфортное и плавное вождение. Стандартно автомобиль поставляется с системами помощи при вождении и различными активными функциями безопасности.`,
        },
        {
          subtitle: "Экстерьер LiXiang L6:",
          text: `Внешний вид LiXiang L6 демонстрирует современные дизайнерские решения, сочетая элегантность и функциональность. Автомобиль имеет размеры 4925x1960x1735 мм и колесную базу 2920 мм. Легкосплавные диски имеют размеры 255/50 R20. Л6 также оснащен скрытым дворником заднего стекла и активной решеткой воздухозаборника.`,
        },
        {
          subtitle: "Интерьер LiXiang L6:",
          text: `LiXiang L6 предлагает роскошный интерьер с двумя рядами сидений и пятью посадочными местами. Сиденья отделаны натуральной кожей наппа и оснащены функциями обогрева и вентиляции. 
      
      Кабина оснащена четырьмя экранами, включая центральный экран управления, развлекательный экран для второго пилота и HUD-проекцию на лобовое стекло. Экран для задних пассажиров в комплекте Ultra. 
      
      Интерьер автомобиля включает премиальную аудиосистему Dolby Atmos с 19 динамиками, трехзонный климат-контроль, атмосферную подсветку из 256 цветов и панорамную крышу. Также предусмотрены функции беспроводной зарядки и интеллектуальный холодильник с обогревом и охлаждением.`,
        },
        {
          subtitle: "Отличие комплектаций LiXiang L6:",
          text: `LiXiang L6 доступен в различных комплектациях, каждая из которых имеет свои особенности и опции. Основные отличия включают дополнительные функции в системах помощи при вождении, количество экранов и уровень отделки интерьера. Комплектации отличаются по объемам батареи, количеству камер и лидаров, а также наличию отдельных опций и улучшений.`,
        },
      ],
    },
    table: [
      ["LiXiang L6", "Standard", "Premium"],
      ["Тип кузова:", "Внедорожник (5 мест)", "Внедорожник (5 мест)"],
      ["Официальное время разгона 0—100 км/ч", "5,4 сек.", "5,4 сек."],
      ["Запас хода на чистом электричестве", "212 (км)", "212 (км)"],
      ["Полный запас хода", "1390 (км)", "1390 (км)"],
      ["Объем багажного отделения (л)", "N/A", "N/A"],
      ["Длина х Ширина х Высота", "4925x1960x1735 (мм)", "4925x1960x1735 (мм)"],
      ["Описание двигателя", "300 кВт", "300 кВт"],
      [
        "Режим вождения",
        "Двухмоторный полный привод",
        "Двухмоторный полный привод",
      ],
      ["Емкость аккумулятора", "N/A", "N/A"],
      ["Общий крутящий момент двигателя (Н·м)", "N/A", "N/A"],
      ["Пневматическая подвеска", "●", "●"],
      ["Колесная база", "2920 (мм)", "2920 (мм)"],
      ["Максимальная скорость", "N/A", "N/A"],
      ["Масса: Снаряженная / Полной нагрузки", "N/A", "N/A"],
      ["Задний ЖК-экран", "N/A", "N/A"],
      ["Аудио / Количество динамиков (шт.)", "N/A", "N/A"],
      ["Операционная система помощи при вождении", "Mind GPT", "Mind GPT"],
      [
        "Чип для помощи при вождении",
        "Qualcomm Snapdragon 8295P",
        "Qualcomm Snapdragon 8295P",
      ],
      ["Вспомогательная вычислительная мощность чипа (TOPS)", "N/A", "N/A"],
      [
        "Автомобильный смарт-чип",
        "Qualcomm Snapdragon 8295P",
        "Qualcomm Snapdragon 8295P",
      ],
      ["Оперативная память автомобиля (ГБ)", "N/A", "N/A"],
      ["Системная память автомобиля (ГБ)", "N/A", "N/A"],
      ["Количество камер снаружи автомобиля (шт.)", "11", "11"],
      ["Количество лидаров (шт.)", "1", "1"],
    ],
  },
];
