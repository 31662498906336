import React from "react";
// import aboutBgImage from "../../images/content/aboutBg.webp";
import aboutBgImage from "../../images/content/about-bg.png";
import OrderConsult from "../../components/OrderConsult/OrderConsult";

const AaboutUs = () => {
  return (
    <>
      <div className="about-us__image">
        <img src={aboutBgImage} alt="" />
      </div>
      <div className="about-us">
        <h2 className="about-us__title">О нас</h2>
        <div className="about-us__about-row">
          <p className="about-us__text">
            АНФА AUTO основана совместно с китайским дистрибьютором автомобилей
            премиум класса с центральным офисом в г. Чунцин (Китай) и
            представительством в г. Москва.
            <br />
            <br />
            АНФА AUTO – это китайско-российская компания. Это значит, что мы
            будем строить бизнес как это делают в Китае. Быстро, качественно и
            технологично. Это так же значит, что мы свои в Китае и не будет
            “трудностей перевода”. Так мы делаем цены — лучше, доставку —
            быстрее, послепродажное обслуживание — качественнее.
          </p>

          <div className="about-us__exp">
            <h3>Опыт. </h3>
            <p>
              Отдел логистики компании успешно работает на рынке 6 лет и
              гарантирует слаженность всех процессов, полностью закрывая
              моменты, связанные с регистрацией автомобиля в России.
            </p>
          </div>

          <div className="about-us__technology">
            <h3>Технологии. </h3>
            <p>
              АНФА AUTO — это технологическая компания нового поколения. Мы
              делаем ставку на технологии и автоматизацию. Технологии нужны,
              чтобы сделать процессы эффективнее, а процесс покупки автомобиля —
              быстрым и прозрачным для клиента. <br />
              <br /> АНФА AUTO – создает лучшую сервисную программу
              послепродажного обслуживания электромобилей. В этом нам помогают
              наши китайские друзья – знаниями, доступом к ресурсам и
              технологиям. Любой владелец электромобиля в любой точки России
              может рассчитывать на экспертную помощь со стороны АНФА AUTO.
            </p>
          </div>

          <div className="about-us__goal">
            <h3></h3>
            <p>
              АНФА AUTO — компания, которая формирует рынок электромобилей в
              России, формирует инфраструктуру для электромобилей в России. Мы
              взращиваем культуру бережного использования ресурсов и транслируем
              wellness подход.
            </p>
          </div>
        </div>

        <h2 className="about-us__title2">Клиентам</h2>
        <div className="about-us__client-row">
          <p className="about-us__text">
            С момента приобретения вами автомобиля, наша команда гарантирует
            клиентскую поддержку в долгосрочной перспективе, оказывая
            высококлассный сервис на протяжении всего времени пользования
            автомобилем.
            <br />
            <br />
            Все автомобили в обязательном порядке проходят 3-х ступенчатую
            проверку качества: в Китае перед отправкой, по прибытии в Россию и
            непосредственно перед передачей клиенту, включая этапы предпродажной
            подготовки.
            <br />
            <br />
            Благодаря тесному сотрудничеству с сервисными центрами
            производителей, клиенты своевременно получают ответы на любые
            вопросы использования автомобилей. На горячей линии ежедневно
            находится до пяти сотрудников техцентра Lixiang в Чунцине.
          </p>

          <div className="about-us__club-cards">
            <h3>Клубные карты</h3>
            <p>
              АНФА AUTO предоставляет клиентам исключительную возможность
              пользоваться услугами помощи на дорогах, партнерскими привилегиями
              и услугами aftersales менеджера.
            </p>
          </div>
        </div>
        <OrderConsult />
      </div>
    </>
  );
};

export default AaboutUs;
