import { configureStore } from "@reduxjs/toolkit";
import burgerReducer from "./reducers/BurgerSlice";
import modalReducer from "./reducers/ModalSlice";

export const store = configureStore({
  reducer: {
    burger: burgerReducer,
    modal: modalReducer,
  },
});
