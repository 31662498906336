import React, { useRef } from "react";
import BannerContent from "./BannerContent";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import backImg1 from "../../images/content/bgUp.jpg";
import backImg2 from "../../images/content/backB.webp";

const BannerSlider = () => {
  const slider = useRef(null);

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
  };

  return (
    <div className="banner-slider">
      <div className="slider-container">
        <Slider ref={slider} {...settings}>
          <BannerContent img={backImg1} />
          <BannerContent img={backImg2} />
        </Slider>
        <div className="slider__btns">
          <button
            className="custom-prev-arrow"
            onClick={() => slider?.current?.slickPrev()}
          ></button>
          <button
            className="custom-next-arrow"
            onClick={() => slider?.current?.slickNext()}
          ></button>
        </div>
      </div>
    </div>
  );
};

export default BannerSlider;
