import React from "react";
import Masonry from "react-masonry-css";
import ImageZoom from "../ImageZoom/ImageZoom";

const Gallery = ({ images }) => {
  // Задайте размеры колонок для Masonry
  const breakpointColumnsObj = {
    default: 3,
    1100: 3,
    700: 3,
    500: 3,
  };

  return (
    <div className="gallery-container">
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
        updateOnEachImageLoad={true}
      >
        {images.map((image, index) => (
          <div key={index} className="image-item">
            {/* <img src={image} alt={`Gallery item ${index}`} /> */}
            <ImageZoom src={image} alt={`Gallery item ${index}`} />
          </div>
        ))}
      </Masonry>
    </div>
  );
};

export default Gallery;
