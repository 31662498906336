import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="privacy">
      <div className="container">
        <h2 className="privacy__title">
          Политика конфиденциальности ООО "Анфа"
        </h2>

        <p className="privacy__text">
          ООО "Анфа" далее — "Компания" уважает право своих клиентов на
          конфиденциальность и обязуется защищать персональные данные,
          предоставляемые клиентами. Настоящая политика конфиденциальности
          описывает, какие данные собираются, как они используются, и какие меры
          предпринимаются для их защиты.
        </p>

        <div className="privacy__content">
          <div className="privacy__block">
            <h3 className="privacy__subtitle">Сбор персональных данных</h3>
            <ul className="privacy__list">
              <li>
                1.1. Компания собирает персональные данные, предоставляемые
                клиентами при заполнении форм на сайте, заключении договоров,
                регистрации на мероприятиях и в иных случаях.
              </li>
              <li>
                1.2. Персональные данные могут включать, но не ограничиваются:
                имя, фамилию, адрес, контактные данные (телефон, электронная
                почта), паспортные данные, данные о транспортных средствах,
                финансовую информацию и другие сведения, необходимые для
                выполнения обязательств перед клиентами.
              </li>
            </ul>
          </div>

          <div className="privacy__block">
            <h3 className="privacy__subtitle">
              Использование персональных данных
            </h3>
            <ul className="privacy__list">
              <li>
                2.1. Персональные данные используются для: Обработки заявок и
                заказов клиентов. Заключения и исполнения договоров. Обеспечения
                качественного обслуживания и поддержки клиентов. Отправки
                информационных и рекламных материалов. Улучшения работы сайта и
                предоставляемых услуг.
              </li>
              <li>
                2.2. Персональные данные не передаются третьим лицам, за
                исключением случаев, предусмотренных законодательством или
                необходимых для исполнения обязательств перед клиентами
                (например, передача данных в сервисные центры для выполнения
                гарантийных обязательств). Защита персональных данных
              </li>
              <li>
                3.1. Компания принимает все необходимые организационные и
                технические меры для защиты персональных данных от
                несанкционированного доступа, изменения, раскрытия или
                уничтожения.
              </li>
              <li>
                3.2. Доступ к персональным данным имеют только те сотрудники
                компании, которым это необходимо для выполнения своих служебных
                обязанностей.
              </li>
            </ul>
          </div>

          <div className="privacy__block">
            <h3 className="privacy__subtitle">Хранение персональных данных</h3>
            <ul className="privacy__list">
              <li>
                4.1. Персональные данные хранятся в течение срока, необходимого
                для достижения целей их обработки, или в течение срока,
                установленного законодательством.
              </li>
              <li>
                4.2. По истечении указанного срока данные подлежат уничтожению
                или обезличиванию.
              </li>
            </ul>
          </div>

          <div className="privacy__block">
            <h3 className="privacy__subtitle">Права клиентов</h3>
            <ul className="privacy__list">
              <li>
                5.1. Клиенты имеют право: Получать информацию о своих
                персональных данных, обрабатываемых компанией. Требовать
                исправления неточных или устаревших данных. Отзывать свое
                согласие на обработку персональных данных. <br />
                Требовать удаления своих данных в случаях, предусмотренных
                законодательством.
              </li>
              <li>
                5.2. Для реализации своих прав клиенты могут обратиться в
                компанию по указанным контактным данным.
              </li>
            </ul>
          </div>

          <div className="privacy__block">
            <h3 className="privacy__subtitle">
              Изменения в политике конфиденциальности
            </h3>
            <ul className="privacy__list">
              <li>
                6.1. Компания оставляет за собой право вносить изменения в
                настоящую политику конфиденциальности. Изменения вступают в силу
                с момента их публикации на сайте компании.
              </li>
              <li>
                6.2. Рекомендуется регулярно проверять актуальность данной
                политики конфиденциальности.
              </li>
            </ul>
          </div>

          <div className="privacy__block">
            <h3 className="privacy__subtitle">Контактная информация</h3>
            <ul className="privacy__list">
              <li>
                7.1. По вопросам, связанным с обработкой персональных данных,
                клиенты могут обращаться по следующим контактным данным:
              </li>
              <li>ООО "Анфа"</li>
              <li>
                Адрес: Г. Москва, Вн. Тер. Г. Муниципальный округ
                преображенское, Ул. Ибграгимова, Д. 35 стр. 2, Помещ. 14H
              </li>
              <li>Телефон: 8 936 285 88 98</li>
              <li>
                Электронная почта:{" "}
                <a
                  style={{ color: "black" }}
                  href="mailto:an.fa.88@mail.com?subject=Me"
                >
                  an.fa.88@mail.com
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
