import { React, useState, useEffect, useRef } from "react";
import OrderConsultModal from "./OrderConsultModal";

const OrderConsult = () => {
  const [modal, setModal] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    modal
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "visible");

    return () => {
      document.body.style.overflow = "visible";
    };
  }, [modal]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      // setIsVisible(entry.isIntersecting);
      if (entry.isIntersecting) {
        entry.target.classList.add("toAnimation");
      }
    });

    observer.observe(elementRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <>
      <div className="order-consult">
        <div ref={elementRef} className="order-consult__content">
          <h4 className="order-consult__title">Заказать консультацию</h4>
          <p className="order-consult__text">
            Свяжитесь с нами и специалисты помогут выбрать Ваш идеальный
            автомобиль
          </p>
          <div className="order-consult__btn">
            <button onClick={() => setModal(true)}>
              Заказать обратный звонок
            </button>
          </div>
        </div>
      </div>
      {modal && <OrderConsultModal setModal={setModal} />}
    </>
  );
};

export default OrderConsult;
