import React from "react";
import { Link } from "react-router-dom";

const BannerContent = ({ img }) => {
  return (
    <div className="banner-content">
      <div className="banner-content__img">
        <img src={img} alt="" />
      </div>
      <div className="banner-content__content">
        <h1 className="banner-content__title">ДОБРО ПОЖАЛОВАТЬ В КЛУБ</h1>
        <div className="banner-content__nav">
          <Link to="/cars" className="banner-content__btnText">
            Узнать больше
          </Link>
          {/* <button className="banner-content__btnArrow"></button> */}
        </div>
      </div>
    </div>
  );
};

export default BannerContent;
