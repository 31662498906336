import React from "react";
import telImg from "../../images/png/icons/tel.png";
import whatsImg from "../../images/png/icons/whats.png";
import vkImg from "../../images/png/icons/vk.png";
import { Link } from "react-router-dom";
import logoImg from "../../images/png/logo/logocarWith.png";
import ScrollToTop from "../ScrollTop/ScrollTop";

const Footer = () => {
  return (
    <footer className="footer">
      <ScrollToTop />
      <div className="footer__row">
        <div className="footer__row-up">
          <div className="footer__logo">
            <Link to="/">
              <img src={logoImg} alt="" />
            </Link>
          </div>
          <div className="footer__network">
            <a
              rel="noreferrer"
              target="_blank"
              href="https://wa.me/+79362858898"
            >
              <img src={whatsImg} alt="" />
            </a>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://t.me/+79362858898"
            >
              <img src={telImg} alt="" />
            </a>
            {/* <a href="">
              <img src={vkImg} alt="" />
            </a> */}
          </div>
        </div>

        <div className="footer__row-down">
          {/* <div className="footer__left">
          <ul className="footer__list">
            <li>Китай</li>
            <li>новый район Лянцзян,</li>
            <li>улица Цзиньшань 89,</li>
            <li>Офис 1-10-3, корпус 4</li>
            <li>Офис 1-10-3, корпус 4</li>
            <li>Чунцин Мей Лин Технолоджи</li>
          </ul>
        </div> */}

          {/* <div className="footer__right"> */}
          <ul className="footer__list">
            <li>{/* <strong>ФИО:</strong> */} "АНФА AUTO" ООО Чжу Вэньцяо</li>
            <li>
              <strong>Адрес:</strong> Г. Москва, Вн. Тер. Г. Муниципальный округ
              Преображенское, Ул. Ибрагимова, Д. 35 стр. 2, Помещ. 14H
            </li>
            <li>
              <strong>Телефон: </strong>
              <a href="tel:+89362858898">8 936 285 88 98</a>
            </li>

            <li>
              <strong>Почта</strong>{" "}
              <a href="mailto:an.fa.88@mail.com?subject=Me">
                an.fa.88@mail.com
              </a>
            </li>
          </ul>
          {/* </div> */}
        </div>
      </div>

      <p className="footer__message">
        ​Вся представленная на сайте информация, касаемо транспортных средств,
        их технических характеристик и стоимости, носит информационный характер
        и ни при каких условиях не является публичной офертой.
      </p>
      <p className="footer__copyright">
        © 2024 ООО «АНФА AUTO» <br /> все права защищены
      </p>

      <p className="footer__privacy">
        ОГРН 1247700374668 | ИНН 9719067995 | КПП 771901001
      </p>

      <p className="footer__privacies">
        <Link to="/privacy">политика конфиденциальности</Link>
        <Link to="/privacy-sell">публичная оферта на продажу автомобилей</Link>
      </p>
    </footer>
  );
};

export default Footer;
