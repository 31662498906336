import React, { useEffect, useState, useRef } from "react";
import motorImg from "../../images/svg/motor.svg";
import rbkImg from "../../images/svg/rbkk.svg";
import shnaImg from "../../images/svg/shina.svg";
import autorImg from "../../images/svg/autoRu.svg";

const News = () => {
  const [activeNew, setActiveNew] = useState(0);
  const [filtered, setFiltered] = useState({});
  const elementRef = useRef(null);

  useEffect(() => {
    const newObj = news.filter((item) => item.id === activeNew)[0];
    setFiltered(newObj);
  }, [activeNew]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        // setIsVisible(entry.isIntersecting);
        if (entry.isIntersecting) {
          entry.target.classList.add("toAnimation");
        }
      },
      {
        root: null,
        threshold: 0.5,
      }
    );

    observer.observe(elementRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  const news = [
    {
      id: 0,
      title: "РБК AUTONEWS",
      new: {
        img: rbkImg,
        title: "В Россию пришел китайский автомобильный дилер",
        text: `« в России начала работать компания АНФА AUTO, которая является филиалом одной из китайских корпораций с экспортной лицензией.
Она намерена напрямую ввозить автомобили из Китая по параллельному импорту. Заказы уже принимаются, открытие первого шоурума в Московском регионе планируется летом 2023 года »

​`,
      },
    },
    {
      id: 1,
      title: "AUTO.RU",
      new: {
        img: autorImg,
        title:
          "В России откроется сеть шоурумов китайского электромобильного бренда Li Auto",
        text: `« Китайско-российская экспортная компания АНФА AUTO объявила о намерении создать в нашей стране сеть шоурумов электромобильного бренда Li Auto (LiXiang). Первый салон обещают открыть в ближайшее время в Сколково. АНФА AUTO также возьмёт на себя сервисное обслуживание машин этой марки. »`,
      },
    },
    {
      id: 2,
      title: "MOTOR.RU",
      new: {
        img: motorImg,
        title:
          "В России появился китайский автомобильный дилер АНФА AUTO Это первый случай, когда дилер из КНР официально выходит на российский рынок",
        text: `« Дилер АНФА AUTO уже открыл прием заказов на автомобили в своем московском офисе, а летом заработает первый шоу-рум. В АНФА AUTO обещают быструю доставку запчастей и комплектующих из Китая, а также пятилетнюю гарантию — то, чего лишены прочие автомобили, которые поставляют в Россию по параллельному импорту. »`,
      },
    },
    {
      id: 3,
      title: "5КОЛЕСО",
      new: {
        img: shnaImg,
        title: "Владельцы электромобилей Li Auto получат поддержку в России",
        text: `« АНФА AUTO – один из крупнейших автодилеров в Китае с головным офисом в Чжэцзян. С 2023 года компания занимается прямыми поставками китайских автомобилей в Россию и даёт на них гарантию сроком до пяти лет. В АНФА AUTO к заказу доступны электромобили и гибриды марок Li Auto, Zeekr, HiPhi, NIO, XPeng, Lotus, BYD, YangWang, Avatr, Voyah, AITO и не только. »`,
      },
    },
  ];

  return (
    <div ref={elementRef} className="news">
      <div className="container">
        <div className="news__box">
          <div className="news__titles">
            {news.map((item) => (
              <span
                className={`${item.id === activeNew ? "active" : ""}`}
                key={item.id}
                onClick={() => setActiveNew(item.id)}
              >
                {item.title}
              </span>
            ))}
          </div>

          <div className="news__row">
            <div className="news__new new">
              <div
                className={`new__logo ${
                  filtered?.title === "MOTOR.RU" ? "motor" : ""
                }`}
              >
                <img src={filtered?.new?.img} alt="" />
              </div>

              <div className="new__content">
                <h6 className="new__title">{filtered?.new?.title}</h6>

                <p className="new__text">{filtered?.new?.text}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default News;
