import React from "react";
import { useEffect, useState, useRef } from "react";

const FlexCard = ({ title, text, img, /* reverse, */ someLeft }) => {
  // const [isVisible, setIsVisible] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      // setIsVisible(entry.isIntersecting);
      if (entry.isIntersecting) {
        entry.target.classList.add("toAnimation");
      }
    });

    observer.observe(elementRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div ref={elementRef} className="flex-card">
      <div className="flex-card__row">
        {/* ${reverse ? "reverse" : ""} ${
            someLeft ? "someLeft" : ""
          } */}
        <div className={`flex-card__left ${someLeft ? "someLeft" : ""}`}>
          <img src={img} alt="" />
        </div>
        {/* ${reverse ? "reverse" : ""} */}
        <div className={`flex-card__right `}>
          <div className="flex-card__content">
            <h3 className="flex-card__title">{title}</h3>
            <p className="flex-card__text">{text}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlexCard;
