import React from "react";
import Brand from "./Brand";

const Brands = () => {
  return (
    <div className="brands">
      <div className="brands__row">
        <Brand />
      </div>
    </div>
  );
};

export default Brands;
