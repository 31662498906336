import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Investors from "./pages/Investors/Investors";
import Partners from "./pages/Partners/Partners";
import AaboutUs from "./pages/AboutUs/AaboutUs";
import Buy from "./pages/Buy/Buy";
import Cars from "./pages/Cars/Cars";
import RootLayout from "./layouts/RootLayout";
import CarProfile from "./pages/CarProfile/CarProfile";
import ContactButton from "./components/ContactButton/ContactButton";
import { useEffect } from "react";
import PrivacyPrlicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import PrivacySell from "./pages/PrivacyPolicy/PrivacySell";
import ScrollToTop from "./components/ScrollTop/ScrollTop";
import ModalAdvanced from "./components/ModalAdvanced/ModalAdvanced";
import { useSelector } from "react-redux";

function App() {
  const { advModal } = useSelector((state) => state.modal);

  useEffect(() => {
    advModal
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "visible");
  }, [advModal]);
  return (
    <BrowserRouter>
      <div className="app">
        <ContactButton />
        {advModal && <ModalAdvanced />}

        <Routes>
          <Route path="/" element={<RootLayout />}>
            <Route index path="/" element={<HomePage />} />
            <Route path="/investors" element={<Investors />} />
            <Route path="/partners" element={<Partners />} />
            <Route path="/about-us" element={<AaboutUs />} />
            <Route path="/buy" element={<Buy />} />
            <Route path="/cars" element={<Cars />} />
            <Route path="/privacy" element={<PrivacyPrlicy />} />
            <Route path="/privacy-sell" element={<PrivacySell />} />
            <Route path="/car-profile/:slug" element={<CarProfile />} />
          </Route>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
