import React from "react";

const PrivacySell = () => {
  return (
    <div className="privacy">
      <div className="container">
        <h2 className="privacy__title">
          Публичная оферта на продажу автомобилей
        </h2>

        <p className="privacy__text">
          ООО "Анфа", именуемое в дальнейшем "Продавец", предлагает любому
          юридическому или физическому лицу, именуемому в дальнейшем
          "Покупатель", заключить договор купли-продажи автомобилей на следующих
          условиях:
        </p>

        <div className="privacy__content">
          <div className="privacy__block">
            <h3 className="privacy__subtitle">Общие положения</h3>
            <ul className="privacy__list">
              <li>
                1.1. Настоящая оферта является публичной офертой в соответствии
                со статьей 437 Гражданского кодекса Российской Федерации.
              </li>
              <li>
                1.2. Размещение текста настоящей оферты в рекламных материалах
                является предложением заключить договор купли-продажи
                автомобилей на изложенных ниже условиях.
              </li>
              <li>
                1.3. Принятие (акцепт) настоящей оферты означает полное и
                безоговорочное принятие Покупателем всех условий настоящей
                оферты.
              </li>
            </ul>
          </div>

          <div className="privacy__block">
            <h3 className="privacy__subtitle">Предмет оферты</h3>
            <ul className="privacy__list">
              <li>
                2.1. Продавец обязуется передать в собственность Покупателя
                автомобиль (автомобили), а Покупатель обязуется принять и
                оплатить автомобиль на условиях настоящей оферты.
              </li>
              <li>
                2.2. Марка, модель, комплектация, цвет и иные характеристики
                автомобиля, а также цена указываются в Спецификации (Приложении)
                к настоящей оферте, которая является неотъемлемой частью
                Договора.
              </li>
            </ul>
          </div>

          <div className="privacy__block">
            <h3 className="privacy__subtitle">Цена и порядок расчетов</h3>
            <ul className="privacy__list">
              <li>3.1. Цена автомобиля указывается в Спецификации.</li>
              <li>
                3.2. Оплата производится в рублях путем перечисления на
                расчетный счет Продавца, указанный в Спецификации, или иным
                способом, согласованным сторонами.
              </li>
              <li>3.3. Сроки и условия оплаты указываются в Спецификации.</li>
              <li>
                3.4. Покупатель считается исполнившим обязательства по оплате с
                момента зачисления денежных средств на расчетный счет Продавца.
              </li>
            </ul>
          </div>

          <div className="privacy__block">
            <h3 className="privacy__subtitle">Порядок передачи автомобиля</h3>
            <ul className="privacy__list">
              <li>
                4.1. Передача автомобиля Покупателю осуществляется в
                согласованном сторонами месте.
              </li>
              <li>
                4.2. Срок передачи автомобиля согласовывается сторонами и
                указывается в Спецификации.
              </li>
              <li>3.3. Сроки и условия оплаты указываются в Спецификации.</li>
              <li>
                4.3. В момент передачи автомобиля стороны подписывают Акт
                приема-передачи, который является подтверждением выполнения
                Продавцом своих обязательств по передаче автомобиля.
              </li>
              <li>
                4.4. Право собственности на автомобиль переходит к Покупателю с
                момента подписания Акта приема-передачи.
              </li>
            </ul>
          </div>

          <div className="privacy__block">
            <h3 className="privacy__subtitle">5. Гарантии и качество</h3>
            <ul className="privacy__list">
              <li>
                5.1. Продавец гарантирует качество автомобиля в соответствии с
                требованиями законодательства Российской Федерации и стандартами
                производителя.
              </li>
              <li>
                5.2. Гарантийный срок эксплуатации автомобиля составляет 3 года
                или 100 000 км пробега (в зависимости от того, что наступит
                раньше) с момента передачи автомобиля Покупателю.
              </li>
              <li>
                5.3. В течение гарантийного срока Продавец обязуется устранять
                выявленные недостатки автомобиля в порядке, установленном
                производителем.
              </li>
            </ul>
          </div>

          <div className="privacy__block">
            <h3 className="privacy__subtitle">Права и обязанности сторон</h3>
            <ul className="privacy__list">
              <li>
                6.1. Продавец обязуется: <br /> Передать автомобиль Покупателю в
                соответствии с условиями настоящей оферты и Спецификации.
                Обеспечить наличие всех необходимых документов, подтверждающих
                право собственности на автомобиль. Предоставить Покупателю всю
                необходимую информацию о технических характеристиках и
                эксплуатации автомобиля.
              </li>
              <li>
                5.2. Гарантийный срок эксплуатации автомобиля составляет 3 года
                или 100 000 км пробега (в зависимости от того, что наступит
                раньше) с момента передачи автомобиля Покупателю.
              </li>
              <li>
                6.2. Покупатель обязуется: <br />
                Принять автомобиль и оплатить его в порядке и сроки,
                установленные настоящей офертой и Спецификацией. Подписать Акт
                приема-передачи в момент получения автомобиля. Соблюдать условия
                эксплуатации автомобиля, установленные производителем.
              </li>
            </ul>
          </div>

          <div className="privacy__block">
            <h3 className="privacy__subtitle">Ответственность сторон</h3>
            <ul className="privacy__list">
              <li>
                7.1. За неисполнение или ненадлежащее исполнение обязательств по
                настоящей оферте стороны несут ответственность в соответствии с
                действующим законодательством Российской Федерации.
              </li>
              <li>
                7.2. Продавец не несет ответственности за невозможность
                использования автомобиля по вине Покупателя или третьих лиц.
              </li>
              <li>
                7.3. В случае нарушения Покупателем сроков оплаты Продавец
                вправе потребовать уплаты неустойки в размере 0.1% от стоимости
                автомобиля за каждый день просрочки.
              </li>
            </ul>
          </div>

          <div className="privacy__block">
            <h3 className="privacy__subtitle">Прочие условия</h3>
            <ul className="privacy__list">
              <li>
                8.1. Настоящая оферта вступает в силу с момента ее опубликования
                и действует до момента отзыва.
              </li>
              <li>
                8.2. Все споры и разногласия, возникающие из настоящей оферты,
                разрешаются путем переговоров. В случае невозможности
                урегулирования споров путем переговоров они подлежат
                рассмотрению в суде по месту нахождения Продавца.
              </li>
              <li>
                8.3. Покупатель подтверждает, что до акцепта настоящей оферты
                ознакомился с ее условиями и всеми приложениями к ней, понимает
                их и принимает безоговорочно.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacySell;
