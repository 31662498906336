import React from "react";
import Brands from "../../components/Brands/Brands";
import OrderConsult from "../../components/OrderConsult/OrderConsult";

const Cars = () => {
  return (
    <div className="cars">
      <Brands />
      <OrderConsult />
    </div>
  );
};

export default Cars;
