import React from "react";
import { Link } from "react-router-dom";

const BrandCard = ({ title, img }) => {
  const titleToSlug = title
    .split("")
    .filter((item) => item !== " ")
    .join("")
    .toLowerCase();

  return (
    <div className="brand-card">
      <Link to={`/car-profile/${titleToSlug}`}>
        <div className="brand-card__row">
          <h5 className="brand-card__title">{title}</h5>

          <div className="brand-card__link">
            <Link to="/">подробнее {">"}</Link>
          </div>

          <div className="brand-card__img">
            <img src={img} alt="" />
          </div>
        </div>
      </Link>
    </div>
  );
};

export default BrandCard;
