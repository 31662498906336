import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setAdvModal } from "../../store/reducers/ModalSlice";

const ContactButton = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(true);

  const toggleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div onClick={toggleOpen} className="contact-button">
      <div className={`contact-button__close ${isOpen ? "" : "isClose"}`}>
        <span></span>
      </div>
      <div className={`contact-button__open ${isOpen ? "isOpen" : ""}`}>
        <span></span>
      </div>

      <div className={`contact-button__interface ${isOpen ? "" : "isOpen"}`}>
        <ul className="contact-button__list">
          <li className="contact-button__telegram">
            <a
              rel="noreferrer"
              target="_blank"
              href="https://t.me/+79362858898"
            >
              Написать в Telegram
            </a>
          </li>
          <li className="contact-button__whatsapp">
            <a
              rel="noreferrer"
              target="_blank"
              href="https://wa.me/+79362858898"
            >
              Написать в Whatsapp
            </a>
          </li>
          <li
            onClick={() => dispatch(setAdvModal(true))}
            className="contact-button__tele"
          >
            Заказать звонок
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ContactButton;
