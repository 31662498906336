import { React, useEffect, useState, useRef } from "react";
import BrandCard from "../BrandCard/BrandCard";
import bgImg from "../../images/content/lix.webp";
// import l9Img from "../../images/png/car/l9.webp";
// import l7Img from "../../images/png/car/l7.webp";
import l9ImgF from "../../images/png/car/l9front (2).png";
import l7ImgF from "../../images/png/car/l7front.png";
import l6Img from "../../images/png/car/l6/1.png";

const Brand = () => {
  const elementRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      // setIsVisible(entry.isIntersecting);
      if (entry.isIntersecting) {
        entry.target.classList.add("toAnimation");
      }
    });

    observer.observe(elementRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div ref={elementRef} className="brand">
      <div className="brand__row">
        <div className="brand__left">
          <div className="brand__content">
            <div className="brand__logo">
              <img src="" alt="" />
            </div>

            <h6 className="brand__title">Lixiang</h6>
            <p className="brand__text">
              В любом из автомобилей Li Auto вы чувствуете себя так, словно не
              выходили из дома. <br /> <br /> В модельном ряду бренда крупные
              внедорожники с огромным запасом пространства, качественными
              материалами и обилием функционального оснащения. Именно за счёт
              последнего Lixiang признан самым умным автомобилем Китая. <br />{" "}
              <br /> А флагманский внедорожник Li Auto L9 завоевал титул лучшего
              автомобиля 2022 года.
            </p>
          </div>
        </div>

        <div className="brand__right">
          <img src={bgImg} alt="" />
        </div>
      </div>

      <div className="brand__cards">
        <BrandCard title="Lixiang L9" img={l9ImgF} />
        <BrandCard title="Lixiang L7" img={l7ImgF} />
        <BrandCard title="Lixiang L6" img={l6Img} />
      </div>
    </div>
  );
};

export default Brand;
