import { useState } from "react";
import { useForm } from "react-hook-form";
import { sendEmail } from "../../utils/sendEmail";

const Partners = () => {
  const [success, setSucces] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    try {
      sendEmail(data);
      setSucces(true);
      reset();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="partners">
      <h3 className="partners__title">Заявка на сотрудничество с АНФА AUTO</h3>
      <p className="partners__text">
        Пожалуйста, оставьте ваш контакт ниже. Наш специалист свяжется с вами
      </p>
      <form
        onSubmit={handleSubmit(onSubmit)}
        action=""
        className="partners__form"
      >
        <div className="partners__email">
          <input
            style={{ borderColor: `${errors.email ? "red" : ""}` }}
            {...register("email", { required: true })}
            placeholder="email"
            type="email"
          />
        </div>
        <div className="partners__btn">
          <button>Отправить заявку</button>
        </div>
        {success && (
          <p style={{ marginTop: "20px" }} className="partners__success">
            Спасибо!
          </p>
        )}
      </form>
    </div>
  );
};

export default Partners;
