import React, { useState } from "react";
import Modal from "react-modal";

Modal.setAppElement("#root"); // Установите это, чтобы избежать предупреждения доступа к элементу вне DOM

const ImageZoom = ({ src, alt }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  return (
    <>
      <img
        src={src}
        alt={alt}
        onClick={openModal}
        style={{ cursor: "pointer" }}
      />

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Image Zoom"
        className="zoomImage"
        style={{
          content: {
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            border: "none",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
          },
        }}
      >
        <div
          onClick={closeModal}
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <img
            src={src}
            alt={alt}
            style={{ maxWidth: "90%", maxHeight: "90%" }}
          />
        </div>
      </Modal>
    </>
  );
};

export default ImageZoom;
