import { useRef, useState, useEffect } from "react";
import OrderConsult from "../../components/OrderConsult/OrderConsult";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ToggleSwitch from "../../components/ToggleSwitch/ToggleSwitch";
import Gallery from "../../components/Gallery/Gallery";
import { lixiangData } from "../../staticData/lixiangData";
import { useParams } from "react-router-dom";

const CarProfile = () => {
  const slider = useRef(null);
  const [activeNew, setActiveNew] = useState("");
  const [data, setData] = useState({
    banner: "",
    slider: [],
    version: [],
    descripition: { content: [] },
    table: [],
    gallery: [],
  });
  const [toggleDesc, setToggleDesc] = useState(false);
  const [toggleCompl, setToggleCompl] = useState(false);
  const [toggleChoose, setToggleChoose] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [price, setPrice] = useState(0);
  const params = useParams();

  // Обработчик изменений для обновления состояния
  const handleChange = (event) => {
    setSelectedOption(event.target.value);
    const filter = data.version.filter(
      (item) => item.model === event.target.value
    );
    setPrice(filter[0]?.price || 0);
  };

  const handleToggle = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    const filteredData = lixiangData.find(
      (item) =>
        item.title
          .split("")
          .filter((char) => char !== " ")
          .join("")
          .toLowerCase() === params.slug
    );

    if (filteredData) {
      setData(filteredData);
      setPrice(filteredData.version[0]?.price || 0);
    }
  }, [params.slug]);

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
  };

  return (
    <div className="car-profile">
      <div className="car-profile__bg-image">
        <img src={data?.banner} alt="" />
      </div>
      <div className="container">
        <div className="car-profile__container">
          <div className="car-profile__car">
            <h3 className="car-profile__title">{data.title}</h3>

            <div className="car-profile__slider">
              <div className="slider-container">
                <Slider ref={slider} {...settings}>
                  {data.slider?.map((item, index) => (
                    <div key={index} className="car-profile__slide">
                      <img src={item} alt="" />
                    </div>
                  ))}
                </Slider>
                <div className="slider__btns">
                  <button
                    className="custom-prev-arrow"
                    onClick={() => slider?.current?.slickPrev()}
                  ></button>
                  <button
                    className="custom-next-arrow"
                    onClick={() => slider?.current?.slickNext()}
                  ></button>
                </div>
              </div>
            </div>

            <div className="car-profile__nds">
              <div className="car-profile__left">
                <div className="car-profile__compl">
                  <select
                    onChange={handleChange}
                    className="car-profile__compl-select"
                  >
                    {data.version?.map((item) => (
                      <option key={item.model} value={item.model}>
                        {item.model}
                      </option>
                    ))}
                  </select>
                </div>

                <p className="car-profile__price">
                  {isChecked
                    ? Math.floor((+price * 20) / 120) + +price
                    : +price}
                  ₽
                </p>
              </div>

              {/* <ToggleSwitch isChecked={isChecked} handleToggle={handleToggle} /> */}
            </div>

            <OrderConsult />

            <div className="car-profile__info">
              <div className="car-profile__desciption">
                <h5 onClick={() => setToggleDesc((prev) => !prev)}>
                  {"> "}Описание
                </h5>
                {toggleDesc && (
                  <div className="car-profile__desc-box">
                    <h2 className="car-profile__desc-title">{data.title}</h2>
                    {data.descripition?.content?.map((item, index) => (
                      <>
                        <h6 className="car-profile__desc-subtitle">
                          {item.subtitle}
                        </h6>
                        <p className="car-profile__desc-text">{item.text}</p>
                      </>
                    ))}
                  </div>
                )}
              </div>

              <div
                onClick={() => setToggleCompl((prev) => !prev)}
                className="car-profile__difference"
              >
                <h5>{"> "}Отличие комплектаций</h5>
                {toggleCompl && (
                  <div className="car-profile__comparison-table">
                    {data.table?.map((item, ind) =>
                      ind === 0 ? (
                        <div key={ind} className="car-profile__header">
                          {item.map((list, index) => (
                            <div key={index}>{list}</div>
                          ))}
                        </div>
                      ) : (
                        <div key={ind} className="car-profile__row">
                          {item.map((list, index) => (
                            <div key={index}>{list}</div>
                          ))}
                        </div>
                      )
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Gallery images={data.gallery} />
    </div>
  );
};

export default CarProfile;
