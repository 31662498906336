import React, { useRef, useState } from "react";
import { sendNumber } from "../../utils/sendNumber";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import ScrollTop from "../ScrollTop/ScrollTop";

const OrderConsultModal = ({ setModal }) => {
  const numberRef = useRef(null);
  const [success, setSucces] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const handleChange = (e) => {
    let value = e.target.value.replace(/\D/g, "");
    if (value.length > 11) {
      value = value.slice(0, 11);
    }
    setValue("from_number", value, { shouldValidate: true });
  };

  const onSubmit = (data) => {
    try {
      sendNumber(numberRef.current);
      setSucces(true);
      reset();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div onClick={() => setModal(false)} className="order-modal__bg"></div>

      <div className="order-modal">
        <h2 className="order-modal__title">Заказать обратный звонок</h2>
        <form ref={numberRef} onSubmit={handleSubmit(onSubmit)} action="">
          <div className="order-modal__phone">
            <input
              style={{ borderColor: `${errors.from_number ? "red" : ""}` }}
              {...register("from_number", {
                required: true,
                pattern: {
                  value: /^(\+7|7|8)\d{10}$/,
                  message: "Invalid phone number format",
                },
              })}
              placeholder="Телефон"
              onChange={handleChange}
              name="from_number"
              type="text"
            />
          </div>

          <div className="order-modal__btn">
            <button>Заказать</button>
            {success && <span>Менеджер скоро перезвонит!</span>}
          </div>

          <div className="order-modal__checkbox">
            {/* <input
              style={{ background: `${errors.check ? "red" : ""}` }}
              {...register("check", { required: true })}
              type="checkbox"
            /> */}
            <label className="custom-checkbox">
              <input
                {...register("check", { required: true })}
                type="checkbox"
              />
              <span
                style={{ borderColor: `${errors.check ? "red" : ""}` }}
                className="checkbox-label"
              ></span>
            </label>
            <label htmlFor="">
              Нажимая кнопку "Отправить", Вы соглашаетесь с{" "}
              <Link onClick={() => setModal(false)} to="/privacy">
                политикой конфиденциальности
              </Link>
            </label>
          </div>
        </form>
        <div onClick={() => setModal(false)} className="order-modal__close">
          <span></span>
        </div>
      </div>
    </>
  );
};

export default OrderConsultModal;
