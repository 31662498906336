import buyImg from "../../images/content/buyBg.webp";

const Buy = () => {
  return (
    <>
      <div className="buy__image">
        <img src={buyImg} alt="" />
      </div>
      <div className="buy">
        <div className="buy__content">
          <h2 className="buy__title">Программа покупки</h2>
          <div className="buy__subtitle">
            <span> Лизинг </span>
            <span>/</span>
            <span>Страхование</span>
          </div>
          <h5 className="buy__info">
            Покупка автомобиля проходит в несколько этапов:
          </h5>
          <ul className="buy__list">
            <li>— Индивидуальная консультация с персональным менеджером.</li>
            <li>
              — Тест-драйв и просмотр автомобиля при его наличии в салоне в
              Москве.
            </li>
            <li>
              — Заключение договора. Дополнительные услуги - по Вашему желанию.
            </li>
            <li>
              — Проводим осмотр автомобиля по видеосвязи с нашим китайским
              менеджером, фиксируем комплектность, отправляется видео продукта и
              VIN номер.
            </li>
            <li>
              — Транспортировка автомобиля (средние сроки 1,5-2 месяца), сбор
              полного пакета документов под ключ, постановка учет и выдача
              полного пакета документов.
            </li>
            <li>
              — Подготовка автомобиля перед передачей клиенту: детейлинг мойка и
              чистка салона, и другие приятные опции за наш счёт от наших
              партнёров.
            </li>
          </ul>
          <p className="buy__text">
            Оплата происходит по контракту, условия которого индивидуально
            согласуются с персональным менеджером.
            <br />
            <br />
            Указанные на сайте цены не являются публичной офертой и могут
            варьироваться в зависимости от курса валют и логистических условий.
            китайские электромобили
          </p>
        </div>
      </div>
    </>
  );
};

export default Buy;
