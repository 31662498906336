import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { sendEmail } from "../../utils/sendEmail";

const Investors = () => {
  const emailRef = useRef(null);
  const [success, setSucces] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    try {
      sendEmail(emailRef.current);
      setSucces(true);
      reset();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="investors">
      <h3 className="investors__title">Сотрудничество с инвесторами</h3>
      <p className="investors__text">
        Пожалуйста, оставьте ваш контакт ниже. Наш специалист свяжется с вами
      </p>
      <form
        ref={emailRef}
        onSubmit={handleSubmit(onSubmit)}
        action=""
        className="investors__form"
      >
        <div className="investors__email">
          <input
            style={{ borderColor: `${errors.from_email ? "red" : ""}` }}
            {...register("from_email", { required: true })}
            placeholder="email"
            type="email"
          />
        </div>
        <div className="investors__btn">
          <button>Отправить заявку</button>
        </div>
        {success && (
          <p style={{ marginTop: "20px" }} className="investors__success">
            Спасибо!
          </p>
        )}
      </form>
    </div>
  );
};

export default Investors;
