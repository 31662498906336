import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import telImg from "../../images/png/icons/tel.png";
import whatsImg from "../../images/png/icons/whats.png";
import vkImg from "../../images/png/icons/vk.png";
import Burger from "../Burger/Burger";
import { useDispatch, useSelector } from "react-redux";
import logoImg from "../../images/png/logo/logocarWith.png";
import { closeBurger } from "../../store/reducers/BurgerSlice";
import ScrollToTop from "../ScrollTop/ScrollTop";

const Header = () => {
  const { burger } = useSelector((state) => state.burger);
  const dispatch = useDispatch();

  useEffect(() => {
    burger
      ? (document.body.style = `overflow: hidden`)
      : (document.body.style = `overflow: visible`);
  }, [burger]);

  const handleClose = () => {
    dispatch(closeBurger());
  };

  return (
    <header className="header">
      <ScrollToTop />
      <div className="header__container">
        <div className="header__row">
          <div className={`header__left ${burger ? "active" : ""}`}>
            <div className="header__logo">
              <Link to="/">
                <img src={logoImg} alt="" />
              </Link>
            </div>
            <div className="header__number2">
              <a href="tel:+89362858898">8 (936) 285-88-98</a>
            </div>
            <nav className={`header__nav ${burger ? "active" : ""}`}>
              <ul className={`header__list ${burger ? "active" : ""}`}>
                <li>
                  <Link onClick={handleClose} to="/cars">
                    АВТОМОБИЛИ
                  </Link>
                </li>
                <li>
                  <Link onClick={handleClose} to="/buy">
                    ПОКУПКА
                  </Link>
                </li>
                <li>
                  <Link onClick={handleClose} to="/about-us">
                    О НАС
                  </Link>
                </li>
                <li>
                  <Link onClick={handleClose} to="/partners">
                    ПАРТНЕРАМ
                  </Link>
                </li>
                <li>
                  <Link onClick={handleClose} to="/investors">
                    ИНВЕСТОРАМ
                  </Link>
                </li>
              </ul>
            </nav>
          </div>

          <div className="header__right">
            <div className="header__number">
              <a href="tel:89362858898">8 (936) 285-88-98</a>
            </div>
            <div className="header__network">
              <a
                rel="noreferrer"
                target="_blank"
                href="https://wa.me/+79362858898"
              >
                <img src={whatsImg} alt="" />
              </a>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://t.me/+79362858898"
              >
                <img src={telImg} alt="" />
              </a>
              {/* <a href="">
                <img src={vkImg} alt="" />
              </a> */}
            </div>
          </div>
          <Burger />
        </div>
      </div>
    </header>
  );
};

export default Header;
