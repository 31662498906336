import React, { useEffect, useRef, useState } from "react";
import modalImg from "../../images/content/modal.jpg";
import { setAdvModal } from "../../store/reducers/ModalSlice";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { sendNumber } from "../../utils/sendNumber";

const ModalAdvanced = () => {
  const formRef = useRef(null);
  const dispatch = useDispatch();

  const [success, setSucces] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const handleChange = (e) => {
    let value = e.target.value.replace(/\D/g, ""); // Удаление всего, что не является цифрами
    if (value.length > 11) {
      // Ограничение длины до 11 цифр
      value = value.slice(0, 11);
    }
    setValue("from_number", value, { shouldValidate: true });
  };

  const onSubmit = (data) => {
    try {
      sendNumber(formRef.current);
      setSucces(true);
      reset();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div
        onClick={() => dispatch(setAdvModal(false))}
        className="modal-advanced__bg"
      ></div>
      <div className="modal-advanced">
        <div
          onClick={() => dispatch(setAdvModal(false))}
          className="modal-advanced__close"
        >
          <span></span>
        </div>
        <div className="modal-advanced__row">
          <div className="modal-advanced__img">
            <img src={modalImg} alt="" />
          </div>
          <div className="modal-advanced__content">
            <h2 className="modal-advanced__title">
              У вас остались вопросы? <br /> Мы ответим
            </h2>
            <p className="modal-advanced__text">
              Наш оператор сам перезвонит вам и проконсультирует абсолютно
              бесплатно по любым вопросам
            </p>

            <form
              ref={formRef}
              onSubmit={handleSubmit(onSubmit)}
              action=""
              className="modal-advanced__form"
            >
              <div className="modal-advanced__name">
                <input
                  style={{ borderColor: `${errors.from_name ? "red" : ""}` }}
                  {...register("from_name", {
                    required: true,
                  })}
                  placeholder="Ваше имя"
                  type="text"
                />
              </div>

              <div className="modal-advanced__number">
                <input
                  style={{ borderColor: `${errors.from_number ? "red" : ""}` }}
                  {...register("from_number", {
                    required: true,
                    pattern: {
                      value: /^(\+7|7|8)\d{10}$/,
                      message: "Invalid phone number format",
                    },
                  })}
                  onChange={handleChange}
                  placeholder="Номер телефона"
                  type="text"
                />
              </div>

              <div className="modal-advanced__btn">
                <button>Оставить заявку</button>
              </div>

              <div className="modal-advanced__check">
                <label className="custom-checkbox">
                  <input
                    {...register("check", { required: true })}
                    type="checkbox"
                  />
                  <span
                    style={{ borderColor: `${errors.check ? "red" : ""}` }}
                    className="checkbox-label"
                  ></span>
                </label>
                <label className="modal-advanced__text2" htmlFor="">
                  Отправляя сведения через электронную форму, Вы даете согласие
                  на обработку, сбор, хранение и передачу третьим лицам
                  представленной Вами информации на условиях{" "}
                  {/* <a href="">Политики обработки персональных данных.</a> */}
                </label>
                {/* <input type="checkbox" />
                <label htmlFor="">
                  Отправляя сведения через электронную форму, Вы даете согласие
                  на обработку, сбор, хранение и передачу третьим лицам
                  представленной Вами информации на условиях{" "}
                  <a href="">Политики обработки персональных данных.</a>
                </label> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalAdvanced;
