import emailjs from "@emailjs/browser";

export const sendNumber = (data) => {
  emailjs
    .sendForm("service_y6s0lbm", "template_2vdo6yh", data, {
      publicKey: "QlQAwl3ARpnfEBu98",
    })
    .then(
      () => {
        console.log("SUCCESS!");
      },
      (error) => {
        console.log("FAILED...", error.text);
      }
    );
};
