import { createSlice } from "@reduxjs/toolkit";

const ModalSlice = createSlice({
  name: "modal",
  initialState: {
    advModal: false,
  },
  reducers: {
    setAdvModal: (state, action) => {
      state.advModal = action.payload;
    },
  },
});

export const { setAdvModal } = ModalSlice.actions;
export default ModalSlice.reducer;
