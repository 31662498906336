import React, { useEffect, useState } from "react";
import Header from "../components/Header/Header";
import BannerSlider from "../components/BannerSlide/BannerSlider";
import FlexCard from "../components/FlexCard/FlexCard";
import OrderConsult from "../components/OrderConsult/OrderConsult";
import News from "../components/News/News";
import Brands from "../components/Brands/Brands";
import Footer from "../components/Footer/Footer";
//imgs
import testimg1 from "../images/content/flexCard/2.jpg";
import testimg2 from "../images/content/flexCard/1new.jpeg";
import testimg3 from "../images/content/flexCard/3new.jpeg";
import testimg4 from "../images/content/flexCard/4.png";
import testimg5 from "../images/content/flexCard/5.jpg";
import testimg6 from "../images/content/flexCard/6.jpg";
import testimg7 from "../images/content/flexCard/2new.jpeg";
import testimg8 from "../images/content/flexCard/8.jpg";
import ContactButton from "../components/ContactButton/ContactButton";
import ModalAdvanced from "../components/ModalAdvanced/ModalAdvanced";
import { useDispatch, useSelector } from "react-redux";
import { setAdvModal } from "../store/reducers/ModalSlice";

const HomePage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(setAdvModal(true));
    }, 15000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div className="home">
      {/* <Header /> */}
      <BannerSlider />
      <FlexCard title="ЛИЗИНГ" text="цена включает полный НДС" img={testimg1} />
      <FlexCard
        title="ОФИЦИАЛЬНАЯ ПОСТАВКА"
        text="прямая поставка в РФвесь комплект документов100% оплата всех таможенных платежей"
        reverse
        img={testimg2}
      />
      <FlexCard
        someLeft
        title="ОФИЦИАЛЬНАЯ ПОСТАВКА"
        text="прямая поставка в РФ
весь комплект документов
100% оплата всех таможенных платежей"
        img={testimg3}
      />
      <FlexCard title="ГАРАНТИЯ" text="от 1 до 5 лет" img={testimg6} />
      <FlexCard
        title="ДОСТАВКА"
        text="срок поставки — 45 дней,закрытый контейнер,GPS трекер"
        reverse
        img={testimg4}
      />
      <FlexCard
        title="ПРОШИВКА И ОБНОВЛЕНИЯ"
        text="локализация и русификация ОС,
установка российских приложений"
        reverse
        img={testimg7}
      />
      <FlexCard
        title="ОРИГИНАЛЬНЫЕ ЗАПАСНЫЕ ЧАСТИ"
        text="
срок поставки - от 7 дней"
        reverse
        img={testimg5}
      />
      <FlexCard
        title="УСТАНОВКА
ЗАРЯДНЫХ УСТРОЙСТВ"
        text="консультирование и техническая поддержка 24/7 "
        reverse
        img={testimg8}
      />
      <OrderConsult />
      <div className="home__image"></div>
      <News />
      <h2 className="brands__title">ПРЕДСТАВЛЯЕМ БРЕНДЫ В РОССИИ</h2>
      <Brands />
      <OrderConsult />

      {/* <Footer /> */}
    </div>
  );
};

export default HomePage;
